import React from "react";
import PropTypes from "prop-types";
import Colors from "../../styles/Colors";
import {
  Div, MDImage, MDInputValue, MDLabel,
} from "../../styles/Styles";
import { DropDownListItem, DropDownWrapper } from "./Styles";
import { ArrowDown } from "../../styles/Icons";
import CustomCheckbox from "./CheckBox";

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    const { id, options } = this.props;
    const selectedValue = this.handleSelectedData(options);
    this.state = {
      isDropdownOpened: false,
      options,
      selectedValue,
      allOptions: options,
    };
    this.selectRef = [];
    this.selectRef[id] = React.createRef();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick, false);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { options: currentOptions = [] } = this.props;
    if (nextProps.options !== currentOptions) {
      let { options: nextOptions = [] } = nextProps;
      nextOptions = nextOptions
        ? nextOptions.map((obj) => ({
          ...obj,
          isSelected: obj.isSelected ? obj.isSelected : false,
        }))
        : [];
      const selectedValue = this.handleSelectedData(nextOptions);
      this.setState({ options: nextOptions, selectedValue, allOptions: nextOptions });
    }
  }

  componentDidUpdate(prevProps) {
    const { options } = this.props;
    if (prevProps.options !== options) {
      this.onOptionsUpdate();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick, false);
  }

  /**
   * Closes dropdown onclicking outside the component
   * @param {event} e captures click event
   */
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    const { isDropdownOpened } = this.state;
    const { id } = this.props;
    if (!this.selectRef[id].contains(e.target) && isDropdownOpened) {
      this.handleDropDown();
    }
  }

  /**
   * Rearranges the options based on selected value
   */
  onOptionsUpdate = () => {
    let { options = [] } = this.props;
    const selectedOptions = options.filter((obj) => obj.isSelected === true);
    options = options.filter((obj) => obj.isSelected !== true);
    options = [...selectedOptions, ...options];
    this.setState({
      options,
      allOptions: options,
    });
  };

  /**
   * Removes duplicated options
   * @param {*} options
   */
  filterUniqueOptions = (options) => {
    const map = {};
    return options.filter((option) => {
      const label = option.label.toLowerCase();
      if (!map[label]) {
        map[label] = label;
        return true;
      }
      return false;
    });
  };

  /**
   * Closes dropdown
   * Rearranges the options based on selected options.
   */
   handleDropDown = () => {
     let { allOptions } = this.state;
     const selectedOptions = allOptions.filter((obj) => obj.isSelected === true);
     allOptions = allOptions.filter((obj) => obj.isSelected !== true);
     allOptions = [...selectedOptions, ...allOptions];
     this.setState((prevState) => ({
       isDropdownOpened: !prevState.isDropdownOpened,
       options: allOptions,
       allOptions,
     }));
   };

  /**
   * Marks particular option is selected among all options
   * @param {*} obj
   */
  optionSelected = (obj) => () => {
    const { allOptions } = this.state;
    const { name, onChange, needId } = this.props;
    let selectedId = "";

    const allOptionsIndex = allOptions.findIndex(
      (allOption) => allOption.label.toLowerCase() === obj.label.toLowerCase(),
    );

    if (allOptionsIndex >= 0) {
      allOptions[allOptionsIndex].isSelected = !allOptions[allOptionsIndex].isSelected;
    }

    const selectedValue = this.handleSelectedData(allOptions);
    if (needId) {
      selectedId = allOptions
        .map((option) => (option.isSelected === true ? option.value : ""))
        .filter((o) => o !== "")
        .join(",");
    }
    if (onChange) {
      let event;
      if (needId) {
        selectedId = allOptions
          .map((option) => (option.isSelected === true ? option.id : ""))
          .filter((o) => o !== "")
          .join(",");
        event = { target: { name, value: { label: selectedValue, value: selectedId } } };
      } else {
        event = { target: { name, value: selectedValue } };
      }
      onChange(event);
    }
    this.setState({ selectedValue, allOptions });
  };

  /**
   * @param {*} options
   * @returns a string with all selected options label
   */
  handleSelectedData = (options) => options
    .map((obj) => (obj.isSelected === true ? obj.label : ""))
    .filter((o) => o !== "")
    .join(", ");

  render() {
    const {
      startIcon,
      id,
      IconHeight,
      formikValues = {},
      name,
      label,
      disabled,
      placeholder = "",
      className,
      width,
      value,
    } = this.props;
    const {
      isDropdownOpened, options, selectedValue,
    } = this.state;
    const { errors = {}, touched = {} } = formikValues;

    return (
      <Div
        width={width}
        disable={disabled}
        ref={(selectRef) => {
          this.selectRef[id] = selectRef;
        }}
        position="relative"
      >
        {label && (
          <Div py={2}>
            <MDLabel fontSize="16px" display="initial">
              {label}
            </MDLabel>
            {errors && errors[name] && touched && touched[name] && (
              <MDLabel float="right" color={Colors.Red}>
                {errors[name]}
              </MDLabel>
            )}
          </Div>
        )}

        <Div
          border="1px solid"
          borderColor={errors && errors[name] && touched[name] ? Colors.Red : Colors.Border}
          display="flex"
          borderRadius={5}
          width={width}
          height="48px"
          cursor="pointer"
          className={className}
          onClick={this.handleDropDown}
        >
          {startIcon && (
            <Div className="col-auto pl-2 pr-0" alignSelf="center" cursor="pointer">
              <MDImage src={startIcon} alt="icon" width="20px" height={IconHeight} />
            </Div>
          )}
          <Div className="col" width="inherit" px={2} py="15px" cursor="pointer">
            {value && value.label
              ? value.label
              : selectedValue ? (
                <MDInputValue>{selectedValue}</MDInputValue>
              ) : placeholder ? (
                <MDInputValue>{placeholder}</MDInputValue>
              ) : (
                ""
              )}
          </Div>
          <Div className="col-auto" alignSelf="center" cursor="pointer" px={2}>
            <ArrowDown className={isDropdownOpened ? "rotate-180" : ""} />
          </Div>
        </Div>
        {isDropdownOpened && (
          <DropDownWrapper>
            {options.map((option) => (
              <DropDownListItem onClick={() => this.optionSelected(option)}>
                <MDInputValue>
                  <CustomCheckbox
                    key={option.value}
                    type="checkbox"
                    label={option.label}
                    onClick={this.optionSelected(option)}
                    isSelected={option.isSelected}
                  />
                </MDInputValue>
              </DropDownListItem>
            ))}
          </DropDownWrapper>
        )}
      </Div>
    );
  }
}
MultiSelect.propTypes = {
  /**
   * Label of the input field
   */
  label: PropTypes.string,
  /**
   * Id of the input
   */
  id: PropTypes.string,
  /**
   * Name of the input
   */
  name: PropTypes.string,
  /**
   * Value of the input
   */
  value: PropTypes.string,
  /**
   * Do input field be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Width of the input field
   */
  width: PropTypes.string,
  /**
   * What options should be shown in dropdown
   */
  options: PropTypes.node,
  /**
   * Calls when value is changed
   */
  onChange: PropTypes.func,
  /**
   * What should be as placeholder..
   */
  placeholder: PropTypes.string,
  /**
   * Formik values
   */
  formikValues: PropTypes.node,
  /**
   * Url of the startIcon
   */
  startIcon: PropTypes.string,
  /**
   * Height of the icon
   */
  IconHeight: PropTypes.string,
  /**
   * Do you want to customize the styles..
   */
  className: PropTypes.string,
  needId: PropTypes.bool,
};
MultiSelect.defaultProps = {
  name: null,
  id: null,
  formikValues: {},
  onChange: undefined,
  placeholder: null,
  disabled: false,
  label: null,
  options: [],
  width: "auto",
  startIcon: null,
  IconHeight: "auto",
  className: null,
  needId: false,
  value: null,
};
export default MultiSelect;
