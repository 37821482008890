import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import PortalContainer from "../../components/Containers/PortalContainer";
import Button from "../../components/Button/Button";
import FilterButton from "../../components/Filter/FilterButton";
import { Div, Header } from "../../styles/Styles";
import ListScreen from "../../components/ListScreen/ListScreen";
import PlusIcon from "../../assets/Icons/PlusIccon.svg";
import Pagination from "../../components/Pagination/Pagination";
import Constants from "../../shared/Constants";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";
import CustomerService, { getCustomersList, getDogBreeds } from "../../services/CustomerService";
import ListScreenLoader from "../../components/Loader/ListScreenLoader";
import Config from "../../config";
import { IsDesktop, IsMobile } from "../../components/ListScreen/Styles";
import { setLoader, loggedInUser } from "../../store/actions/AppAction";
import SortButton from "../../components/Sorting/Sorting";
import AuthService from "../../services/AuthService";
import { checkRightPermission, formatDate, getExceptionPopupContent } from "../../shared/utils";
import SearchBox from "../../components/Input/SearchBox";
import CsvDownloadButton from "../../components/CsvDownload/CsvDownloadButton";
import DeleteSuccessPopUp from "../../components/AvailabilityModal/DeleteSuccessPopUp";

const { language, sorting: { type: sortType, keys: { sortBy, sort } }, adminRoutes, input: { format }, CSV_FILENAME: { CUSTOMERS } } = Constants;
class Customers extends Component {
  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    const { components, options: optionType } = Constants.filter;

    const { userDetails = {}, location : { search } } = this.props;

    const userInfo = userDetails.user_access
      ? userDetails : localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};

    const { permissions = [] } = userInfo;

    const hasWriteAccess= checkRightPermission(permissions,"is_customer_editable",Constants.WRITE);

    const params = new URLSearchParams(search);
    const pageNum = params.get("page");
    const searchWord = params.get("search");

    this.state = {
      hasWriteAccess,
      deleteAccID: "",
      meta: {
        pageNumber: pageNum || 1,
        pageSize: 10,
        total: 0,
        pages: 0,
      },
      sortedBy: "",
      sortAscending: false,
      isFilterOpen: false,
      isDeleteModalOpen: false,
      isUserDeleted: false,
      customers: [],
      filterOptions: [
        {
          id: "status",
          component: components.CHECKBOX,
          label: language.filter_label_status,
          values: optionType.CUSTOMER_STATUS,
          defaultValues: [],
          name: "status",
        },
        {
          id:"dog_breed",
          component: components.MULTISELECT,
          label: language.select_dog_breed,
          values: [],
          defaultValues: {},
          availableValues: "",
          name: "dog_breed",
          placeholder: language.choose_breed,
        },
      ],
      toBeFilteredData: {},
      showSortModal: false,
      filterValues: {},
      searchQuery: searchWord || "",
      isErrorModal: false,
    };
  }

  loadDogBreed = async () => {
    const { filterOptions } = this.state;
    const response = await getDogBreeds();
    const dogBreeds = response.map((o)=>({...o, id: o?.value}));
    const filterColumns = filterOptions.map((obj)=>{
      if(obj?.id === "dog_breed") {
        return {...obj, values: dogBreeds};
      }
      return {...obj};
    });
    this.setState({filterOptions: filterColumns });
  };

  async componentDidMount() {
    const { onLoading, history: { location } } = this.props;
    onLoading(true);
    const { filterOptions, searchQuery } = this.state;
    const search = location.search;
    const params = new URLSearchParams(search);
    const applyFilters = JSON.parse(params.get("filter"));
    const { filter= {}, options = filterOptions } = applyFilters || {};
    this.setState({
      filterOptions: options,
      filterValues: applyFilters || {},
    });
    await this.fetchCustomers(filter, false, options, searchQuery);
    onLoading(false);
    const dogFilter = options?.find((o)=> o?.id==="dog_breed");
    if(!dogFilter?.values?.length) {
      this.loadDogBreed();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      meta: { pageNumber },
      sortedBy,
      sortAscending,
      filterOptions,
      filterValues,
      searchQuery
    } = this.state;
    const { location = {} } = this.props;
    const { filter= {}, options = filterOptions } = filterValues;
    if (
      prevState.meta.pageNumber !== pageNumber
      || location.key !== prevProps.location.key
      || sortedBy !== prevState.sortedBy
      || sortAscending !== prevState.sortAscending
    ) {
      this.fetchCustomers(filter, false, options, searchQuery);
    }
  }

  getDetails = () => {
    const { getLoginUser } = this.props;
    try {
      this.authService.usersDetails().then((response) => {
        getLoginUser(response);
      });
    } catch (e) {
      console.error(e);
    }
  };

  /**
   *
   * @async
   * @function fetchCustomers Fetches all the available customers.
   * @returns data of the rooms.
   *
   */
   fetchCustomers=async (filters, searchInput, options, searchWord) => {
     const { onLoading, customerFilters } = this.props;
     onLoading(true);
     const {
       meta: { pageNumber, pageSize }, sortedBy, sortAscending,
       toBeFilteredData,
       filterOptions,
     } = this.state;
     const filterValues = options || filterOptions;
     const filter = filters || toBeFilteredData;
     let queryParams = [
       ["pageNumber", searchInput ? 1 : pageNumber],
       ["pageSize", pageSize],
       [sortBy, sortedBy],
       [sort, sortAscending ? sortType.ASC : sortType.DESC],
       ["filter", filter],
       ["search_name", searchWord],
       ["is_csv", 0]
     ];
     try {
       const response = await getCustomersList(queryParams);
       filterValues[0].availableValues = customerFilters;
       const { meta: srcMeta = {}, data = [] } = response;
       const {
         current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount,
       } = srcMeta;
       const newMeta = {
         pageNumber: currentPage,
         pageSize: perPage,
         pages,
         totalCount,
       };
       window.scrollTo(0, 0);
       this.setState({
         filterOptions: filterValues,
         customers: data,
         meta: newMeta,
       });
       onLoading(false);
     } catch (e) {
       onLoading(false);
     }
   }

  /**
   *
   * @async
   * @function fetchCsvData Fetches the customers to be downloaded in csv.
   * @returns data of customers.
   *
   */
  fetchCsvData = async () => {
    const { onLoading } = this.props;
    onLoading(true);
    const {
      toBeFilteredData,
      searchQuery
    } = this.state;
    let queryParams = [
      ["filter", toBeFilteredData],
      ["search_name", searchQuery],
      ["is_csv", 1]
    ];
    try {
      const response = await getCustomersList(queryParams);
      const { data = [] } = response;
      onLoading(false);
      return this.getCustomerFieldsForCsv(data);
    } catch (e) {
      this.setState({ isErrorModal: true });
      onLoading(false);
    }
  }

  /**
   *
   * @function handleAddCustomer
   * Redirects to create new room page
   *
   */
  handleAddCustomer = () => {
    const { history } = this.props;
    history.push(Constants.routes.addCustomer.url);
  };

  /**
   *
   * Handles pagination
   * @param {Number} pageNumber page number
   *
   */
  handleGotoPage = (pageNumber) => {
    const { history }=this.props;
    this.setState(({ meta }) => ({
      meta: { ...meta, pageNumber },
    }));
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };

  /**
   * Handles filter actions
   */
  handleCloseFilter = () => {
    const { isFilterOpen } = this.state;
    this.setState({ isFilterOpen: !isFilterOpen });
  };

  handleFilterApply=(values = [], options = undefined) => {
    const { history }=this.props;
    const { searchQuery } = this.state;
    const filter = {};
    values.map((obj) => {
      if (obj?.value && obj?.value !== undefined) {
        if (filter[obj.name]) {
          filter[obj.name].push(obj.value);
        } else {
          filter[obj.name] = [];
          filter[obj.name].push(obj.value);
        }
      }
      return obj;
    });
    this.setState({
      toBeFilteredData: filter,
      isFilterOpen: false,
      filterValues: {
        filter,
        values,
        options,
      },
    });
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("filter", JSON.stringify({ filter, options }));
    currentUrlParams.set("page", 1);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    this.fetchCustomers(filter, values, options, searchQuery);
  }

  /**
   *
   * Redirects to detailed view page
   * @param {object} item user data
   * @returns to selected user's detailed page
   *
   */
  handleDetailPage = (item) => {
    const { history } = this.props;
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.customers}/${item.id}/${adminRoutes.editCustomer}`);
  };

  handleEditCustomer =(item) => () => {
    const { history } = this.props;
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.customers}/${item.id}/${adminRoutes.editCustomer}`);
  }

  /**
   * Opens a delete confirmation popup
   * @param {object} item selected room data
   */
  handleDeleteModal =(item) => {
    const { id = "" } = item;
    this.setState({ isDeleteModalOpen: true, deleteAccID: id }, () => {
      this.openElement.click();
    });
  };

  /**
   *
   * @async
   * @function handleDeleteCustomer
   * Deletes the selected user.
   * @returns user deletion
   *
   */
   handleDeleteCustomer =async () => {
     const { deleteAccID, searchQuery,  filterOptions, filterValues, } = this.state;
     const { onLoading } = this.props;
     const { filter= {}, options = filterOptions } = filterValues;
     onLoading(true);
     try {
       const response = await this.customerService.deleteCustomer(deleteAccID);
       if (response) {
         this.getDetails();
         this.setState({ isDeleteModalOpen: false, isUserDeleted: true, deleteAccID: "" }, this.fetchCustomers(filter, false, options, searchQuery));
         onLoading(false);
       }
     } catch (e) {
       onLoading(false);
     }
   }

  /**
   * Cancels the user deletion operation
   */
  handleCancel = () => {
    this.setState({ isUserDeleted: false, isDeleteModalOpen: false });
  }

  /**
   *
   * Handles sorting of columns in the list screen
   * @param {object} item column data
   * @returns sorted list
   *
   */
  handleSorting=(item) => () => {
    const { sortedBy, sortAscending } = this.state;
    if (sortedBy === item) {
      this.setState({ sortAscending: !sortAscending });
    } else {
      this.setState({ sortedBy: item, sortAscending: true });
    }
  }

  handleSortModal=() => {
    const { showSortModal } = this.state;
    this.setState({ showSortModal: !showSortModal });
  }

  handleApplySorting=(sortKey = "", sortOrder = false) => {
    this.setState({ sortedBy: sortKey, sortAscending: sortOrder, showSortModal: false });
  }

  handleSearchOnChange = (e) => {
    const {
      target: { value },
    } = e;
    this.setState({ searchQuery: value });
  };

  handleApplySearch = (isEmpty = false) => {
    const { history } = this.props;
    const { searchQuery } = this.state;
    this.setState(({ meta }) => ({
      meta: { ...meta, pageNumber : 1 },
    }));
    this.fetchCustomers("", true, "", isEmpty ? "" : searchQuery);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", 1);
    currentUrlParams.set("search", searchQuery);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };

  handleClearSearch = () => {
    const { history } = this.props;
    this.setState(({ meta }) => ({
      meta: { ...meta, pageNumber : 1 },
      searchQuery: ""
    }));
    this.fetchCustomers("", true);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", 1);
    currentUrlParams.delete("search");
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  }

  getCustomerFieldsForCsv = (customers) => {
    return customers?.map(customer => ({
      "First Name": customer.first_name,
      "Last Name": customer.last_name,
      "Email": customer.email,
      "Phone Number": customer.phone_number_list,
      "Dog Count": customer.dogs?.length,
      "Orders Count": customer.total_orders,
      "Registered Date And Time": formatDate(customer.created_at, format.dateWithTime)
    }));
  }

  handleClosePopup = () => {
    this.setState({ isErrorModal: false });
  }

  render() {
    const {
      meta,
      filterOptions,
      isFilterOpen,
      isDeleteModalOpen,
      isUserDeleted,
      hasWriteAccess,
      customers,
      sortedBy,
      sortAscending,
      showSortModal,
      searchQuery,
      isErrorModal
    } = this.state;
    const { isLoader } = this.props;
    const header = [
      {
        className: "col-3", value: language.placeholder_name, icon: sortType.ALPHABETS, key: "firstname",
      },
      {
        className: "col-2", value: language.list_header_dogs,
      },
      {
        className: "col", value: language.common_orders,
      },
      {
        className: "col", value: language.list_header_open_orders,
      },

    ];
    const value = [
      {
        className: "col-3",
        key: "name",
        type: "user",
        group: false,
        isDesktop: true,
      },
      {
        className: "col-2",
        key: "dogs",
        type: "label",
        group: false,
      },
      {
        className: "col",
        key: "total_orders",
        type: "number",
        group: false,
      },
      {
        className: "col",
        key: "open_orders",
        type: "label",
        group: false,
      },
    ];
    return (
      <PortalContainer>
        <Div justifyContent="space-between" display="flex" pb={4} flexDirection={["column","column","row","row"]}>
          {isErrorModal && <DeleteSuccessPopUp data={getExceptionPopupContent()} renderModal={this.handleClosePopup} />}
          <Div>
            <Header display="inline-block"><FormattedMessage id="common_customers" /></Header>
          </Div>
          <Div display="flex" alignItems="center">
            <IsDesktop>
              <SearchBox
                name ="searchQuery"
                value= {searchQuery}
                onChange={this.handleSearchOnChange}
                onSearch = {this.handleApplySearch}
                onClear= {this.handleClearSearch}
              />
            </IsDesktop>
            <IsDesktop>
              <Div className="row m-0">
                {hasWriteAccess && (
                  <Button
                    label={<FormattedMessage id="common_add_new" />}
                    primary
                    startIcon={PlusIcon}
                    width="175px"
                    onClick={this.handleAddCustomer}
                    padding="10px !important"
                  />
                )}
                <FilterButton
                  open={isFilterOpen}
                  options={filterOptions}
                  closeFilter={this.handleCloseFilter}
                  onApply={this.handleFilterApply}
                />
                <CsvDownloadButton
                  fileName={CUSTOMERS}
                  onCsvClick={this.fetchCsvData}
                />
              </Div>
            </IsDesktop>
          </Div>
        </Div>
        <IsMobile>
          <Div display="flex" justifyContent="space-between" pb={4}>
            {hasWriteAccess && (
              <Div>
                <Button
                  label={<FormattedMessage id="common_add_new" />}
                  primary
                  startIcon={PlusIcon}
                  width="175px"
                  onClick={this.handleAddCustomer}
                  padding="10px !important"
                />
              </Div>
            )}
            <Div display="flex">
              <SortButton
                isOpen={showSortModal}
                sortingKeys={header}
                sortedBy={sortedBy}
                sortAscending={sortAscending}
                onClose={this.handleSortModal}
                onSubmit={this.handleApplySorting}
              />
              <FilterButton
                open={isFilterOpen}
                options={filterOptions}
                closeFilter={this.handleCloseFilter}
                onApply={this.handleFilterApply}
              />
              <CsvDownloadButton
                fileName={CUSTOMERS}
                onCsvClick={this.fetchCsvData}
              />
            </Div>
          </Div>
        </IsMobile>
        <IsMobile pb={20}>
          <SearchBox
            name ="searchQuery"
            value= {searchQuery}
            onChange={this.handleSearchOnChange}
            onSearch = {this.handleApplySearch}
            onClear= {this.handleClearSearch}
          />
        </IsMobile>
        {isLoader && <ListScreenLoader />}
        {!isLoader && (
          <>
            <ListScreen
              isCustomers
              moreButton
              deleteAction={hasWriteAccess}
              editAction={hasWriteAccess}
              header={header}
              value={value}
              data={customers}
              onEdit={this.handleDetailPage}
              onDelete={this.handleDeleteModal}
              onClick={this.handleEditCustomer}
              handleSorting={this.handleSorting}
              sortedBy={sortedBy}
              sortAscending={sortAscending}
              dataNotFoundMessage={<FormattedMessage id="customers_not_found" />}
            />
            {meta.pages > 1 && (
              <Div py={4} display={["block", "flex", "flex"]} >
                <Div display={["flex"]} justifyContent="center" alignItems="center" >
                  {`${customers.length < 10 ? customers.length + ((meta.pageNumber - 1) * 10) : customers.length * meta.pageNumber} / ${meta.totalCount}`}
                </Div>
                <Div width="90%" justifyContent="center">
                  <Pagination width="90%" backgroundColor="pink" gotoPage={this.handleGotoPage} cursor={meta} />
                </Div>
              </Div>
            )}
          </>
        )}
        <a
          href
          data-toggle="modal"
          data-target="#Modal"
          data-backdrop="static"
          data-keyboard="false"
          ref={(openModal) => { this.openElement = openModal; }}
        />
        {isDeleteModalOpen && (
          <Modal>
            <Card
              header={<FormattedMessage id="customer_delete_room" />}
              message={<FormattedMessage id="customer_deleted_confirmation_message" />}
              buttonLabel={<FormattedMessage id="common_delete" />}
              confirmationCard
              buttonIcon={Constants.icons.DeleteIcon}
              onClick={this.handleDeleteCustomer}
              onCancel={this.handleCancel}
              onLoader={isLoader}
            />
          </Modal>
        )}
        {isUserDeleted && (
          <Modal>
            <Card header={<FormattedMessage id="common_done" />} message={<FormattedMessage id="customer_deleted_alert_message" />} alertCard buttonLabel={<FormattedMessage id="common_ok" />} onClick={this.handleCancel} />
          </Modal>
        )}
      </PortalContainer>
    );
  }
}
Customers.propTypes = {
  history: PropTypes.node,
  location: PropTypes.node,
  userDetails: PropTypes.node,
  onLoading: PropTypes.func,
  isLoader: PropTypes.bool,
  customerFilters: PropTypes.node,
  getLoginUser: PropTypes.func,
};
Customers.defaultProps = {
  history: undefined,
  location: null,
  userDetails: {},
  onLoading: () => {},
  isLoader: false,
  customerFilters: [],
  getLoginUser: () => {},
};
const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
  isLoader: state.AppReducer.isLoader,
  customerFilters: state.FilterReducer.customerFilters,
});

function mapDispatchToProps(dispatch) {
  return {
    onLoading: (payload) => dispatch(setLoader(payload)),
    getLoginUser: (payload) => dispatch(loggedInUser(payload)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(Customers);
