const host = "https://api.staging-5em2ouy-cw7lgwpfnh5ru.eu-5.platformsh.site/";
export default {
  domain: host,
  apiBase: `${host}api/v1/`,
  baseName: "/",
  language: "sv",
  key: "AIzaSyBI1klLeRL4BZJxTFGun1515l-5WPMUfUg",
  orderConfirmUrl: "http://staging-5em2ouy-cw7lgwpfnh5ru.eu-5.platformsh.site/order-confirm?order_id={checkout.order.id}",
  markerDestinationId: "6523acd161bda4c9a6bdb9a7",
  wpUrl: "https://mdc02.wpengine.com/",
};
