import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import DatePicker from "../Input/CustomDatePicker";
import Colors from "../../styles/Colors";
import {
  CardHeader, Div, Span, MDLabel, IsDesktop, IsMobile, MDImage,
} from "../../styles/Styles";
import { CardWrapper, Row } from "./Styles";
import Constants from "../../shared/Constants";
import CheckBox from "../Input/CheckBox";
import Button from "../Button/Button";
import TickIcon from "../../assets/Icons/AssignmentsMenuIcon.svg";
import EraseIcon from "../../assets/Icons/EraseIcon.svg";
import { formatDate } from "../../shared/utils";
import MultiSelect from "../Input/MultiSelect";

const {
  filter: { components: filterComponents }, language, themes, icons, input: { format },
} = Constants;
class FilterPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsData: JSON.parse(JSON.stringify(props.options)),
      optionsDataCopy: JSON.parse(JSON.stringify(props.options)),
      selectedData: [],
    };
    this.defaultValue = props.options;

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  /**
   * Adding an event listener to check click event.
   */
  componentDidMount() {
    const { optionsData } = this.state;
    let selectedData = {};

    selectedData = this.onFilterSelectedData(optionsData);
    this.setState({ selectedData });
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    this.handleUpdateFilterData(prevProps, prevState);
  }

  /**
   * Removing added event listener.
   */
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleUpdateFilterData(prevProps) {
    let { selectedData } = this.state;
    const { options } = this.props;

    if (options && prevProps.options !== options) {
      this.setState({
        optionsData: JSON.parse(JSON.stringify(options)),
        optionsDataCopy: JSON.parse(JSON.stringify(options)),
      });
      const optionsData = JSON.parse(JSON.stringify(options));
      selectedData = this.onFilterSelectedData(optionsData);
      this.setState({ selectedData });
    }
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    const filterPopOver = this.findClosest(event.target, (el) => el.id === "filter-pop-over-feature");
    const filterButton = this.findClosest(event.target, (el) => el.id === "filter-button");
    if (filterPopOver === undefined && filterButton === undefined) {
      this.handleCancelFilters();
    }
  }

  onFilterSelectedData = (data = []) => {
    const optionsData = data;
    const selectedData = [];
    const { components } = Constants.filter;
    optionsData.map((item) => {
      if (item.component === components.MULTISELECT) {
        selectedData.push({
          id: item.id,
          name: item.name,
          value: item.defaultValues?.value,
          component: item.component,
          label: item.label,
        });
      }
      if (item.component !== components.DATEPICKER && item.component !== components.MULTISELECT) {
        item.defaultValues.map((obj) => {
          selectedData.push({
            id: item.id,
            name: item.name,
            value: obj,
            component: item.component,
            label: item.label,
          });
          return obj;
        });
      } else if (item.component === components.DATEPICKER) {
        if (item.fromDate.defaultDate) {
          selectedData.push({
            id: item.id,
            name: item.name,
            value: formatDate(item.fromDate.defaultDate, format.date),
            component: item.component,
          });
        }
        if (item.toDate.defaultDate) {
          selectedData.push({
            id: item.id,
            name: item.name,
            value: formatDate(item.toDate.defaultDate, format.date),
            component: item.component,
          });
        }
      }
      return item;
    });
    return selectedData;
  };

  handleApplyFilters = () => {
    const { optionsData } = this.state;
    let selectedData = {};
    const { onApply } = this.props;
    const currentData = JSON.parse(JSON.stringify(optionsData));
    selectedData = this.onFilterSelectedData(optionsData);
    this.setState({ selectedData, optionsDataCopy: currentData });
    if (onApply) {
      onApply(selectedData, optionsData);
    }
  };

  handleCancelFilters = () => {
    const { optionsDataCopy } = this.state;
    const { closeFilter } = this.props;
    const previousData = JSON.parse(JSON.stringify(optionsDataCopy));
    this.setState({
      optionsData: previousData,
    });
    if (closeFilter) {
      closeFilter();
    }
  };

  handleClearFilters = () => {
    const optionsData = this.state.optionsData.map((obj) => {
      const item = {...obj};
      if (item.initialValues) {
        item.defaultValues = [...item.initialValues];
      } else if (item.component === filterComponents.MULTISELECT) {
        item.defaultValues = {};
        item.values = item.values.map((o) => ({ ...o, isSelected: false }));
      } else if (item.component !== filterComponents.DATEPICKER) {
        item.defaultValues = [];
      } else if (item.name === "date_time") {
        item.fromDate.defaultDate = "";
        item.toDate.defaultDate = "";
      }
      return item;
    });
    this.setState({ optionsData });
  };

  handleLetterSelect = (id, value) => () => {
    const { optionsData } = this.state;
    optionsData.map((option) => {
      if (option.id === id) {
        const index = option.defaultValues.indexOf(value);
        if (index > -1) {
          option.defaultValues.splice(index, 1);
        } else {
          option.defaultValues.push(value);
        }
      }
      return option;
    });
    this.setState({ optionsData });
  };

  handleDate = (startDate) => (name, date) => {
    const {
      optionsData,
    } = this.state;
    optionsData.map((option) => {
      const obj = option;
      if (option.component === filterComponents.DATEPICKER && option.id === name) {
        if (startDate) {
          obj.fromDate.defaultDate = date;
        } else {
          obj.toDate.defaultDate = date;
        }
      }
      return obj;
    });
    this.setState({ optionsData });
  };

  handleCheckBoxValues = (id, selectedValue = {}) => () => {
    const { value = "" } = selectedValue;
    const { optionsData } = this.state;
    optionsData.map((option) => {
      if (option.component === filterComponents.CHECKBOX) {
        if (option.id === id) {
          const index = option.defaultValues.indexOf(value);
          if (index > -1) {
            option.defaultValues.splice(index, 1);
          } else {
            option.defaultValues.push(value);
          }
        }
      }
      return option;
    });
    this.setState({ optionsData });
  };

  findClosest(element, fn) {
    if (!element) return undefined;
    return fn(element) ? element : this.findClosest(element.parentElement, fn);
  }

  handleOnChangeMultiSelectValues = (event) => {
    const { name, value } = event?.target;
    const { optionsData } = this.state;
    console.log(event, optionsData);
    optionsData.map((option) => {
      if (option.component === filterComponents.MULTISELECT) {
        if (option.id === name) {
          console.log("enterd here");
          option.defaultValues = value;
        }
      }
      return option;
    });
    this.setState({ optionsData });
  }

  renderMultiSelectComponent = (value) => {
    console.log(value);
    const { values, name, defaultValues, placeholder } = value;
    return(
      <MultiSelect
        name={name}
        options={values}
        value={defaultValues}
        onChange={this.handleOnChangeMultiSelectValues}
        placeholder={placeholder}
        needId
      />
    )};


  renderFromAndToDates = (value) => (
    <div className="row align-items-center">
      {value.fromDate && (
        <div className="col-12 col-md-6">
          <div className="row d-inline-block date-picker-holder">
            <label className="mb-0 filter-date-picker">
              <DatePicker
                onChange={this.handleDate(true)}
                name="date"
                value={value.fromDate.defaultDate}
                placeholder={language.placeholder_from}
              />
            </label>
          </div>
        </div>
      )}
      {value.toDate && (
        <div className="col">
          <div className="row d-inline-block date-picker-holder">
            <label className="mb-0 filter-date-picker">
              <DatePicker
                onChange={this.handleDate(false)}
                name="date"
                value={value.toDate.defaultDate}
                placeholder={language.placeholder_to}
              />
            </label>
          </div>
        </div>
      )}
    </div>
  );



  renderFilter = () => {
    const { optionsData } = this.state;
    const { components, options } = Constants.filter;

    return (
      <Div fontFamily={themes.fonts.semibold}>
        {optionsData.map((option, index) => (
          <Div key={index}>
            {option.component === components.LETTERSELECT && (
              <Row>
                <Div className="col-lg-3 col-md-12 px-0">{option.label}</Div>
                <IsDesktop className="col-lg-9 col-md-12 px-0">
                  <Div display="flex" justifyContent="space-between">
                    {option.values.map((value, index) => (
                      <Span
                        key={index}
                        cursor="pointer"
                        color={option.defaultValues.includes(value) ? Colors.Green : Colors.Black}
                        disable={!option.availableValues.includes(value)}
                        onClick={this.handleLetterSelect(option.id, value)}
                      >
                        {value}
                      </Span>
                    ))}
                  </Div>
                </IsDesktop>
                <IsMobile className="mt-3">
                  {options.MOBILESCREENALPHABETS.map((obj, index) => (
                    <Div key={index}>
                      {obj.map((value, index) => (
                        <Span
                          key={index}
                          color={option.defaultValues.includes(value) ? Colors.Green : Colors.Black}
                          disable={!option.availableValues.includes(value)}
                          onClick={this.handleLetterSelect(option.id, value)}
                          className="d-inline-block text-left"
                          width="10% !important"
                        >
                          {value}
                        </Span>
                      ))}
                    </Div>
                  ))}
                </IsMobile>
              </Row>
            )}
            {option.component === components.CHECKBOX && (
              <Row>
                <Div className="col-lg-3 col-md-12 px-0">{option.label}</Div>
                <Div className="col-lg-9 col-md-12 px-0" alignSelf="center">
                  <Div className="row m-0">
                    {option.values.map((obj, index) => {
                      const { label = "", value = "" } = obj;
                      return (
                        <Div key={index} className={`col-6 py-2 pl-0 pl-md-3 pr-lg-0 d-inline-flex ${obj.className}`}>
                          <CheckBox
                            isSelected={option.defaultValues.includes(value)}
                            onClick={this.handleCheckBoxValues(option.id, obj)}
                            label={label}
                          />
                        </Div>
                      );
                    })}
                  </Div>
                </Div>
              </Row>
            )}
            {option.component === components.DATEPICKER && (
              <Row>
                <Div className="col-lg-3 col-md-12 px-0" alignSelf="center">{option.label}</Div>
                <Div className="col-lg-9 col-md-12">{this.renderFromAndToDates(option)}</Div>
              </Row>
            )}
            {option.component === components.MULTISELECT && (
              <Row>
                <Div className="col-lg-3 col-md-12 px-0" alignSelf="center">{option.label}</Div>
                <Div className="col-lg-9 col-md-12">{this.renderMultiSelectComponent(option)}</Div>
              </Row>
            )}
          </Div>
        ))}
        <IsDesktop>
          <Row justifyContent="center">
            <Button
              primary
              label={<FormattedMessage id="common_ok" />}
              className="mx-2"
              width="160px"
              startIcon={TickIcon}
              onClick={this.handleApplyFilters}
            />
            <Button
              secondary
              label={<FormattedMessage id="filter_clear_filter" />}
              className="mx-2"
              startIcon={EraseIcon}
              onClick={this.handleClearFilters}
            />
            <Button
              secondary
              label={<FormattedMessage id="common_cancel" />}
              className="mx-2"
              width="160px"
              onClick={this.handleCancelFilters}
            />
          </Row>
        </IsDesktop>
        <IsMobile>
          <Div className="row m-0">
            <Button
              primary
              label={<FormattedMessage id="common_ok" />}
              className="mx-4 my-3"
              width="100%"
              startIcon={TickIcon}
              onClick={this.handleApplyFilters}
            />
          </Div>
          <Div className="row mb-4 mx-4" justifyContent="space-between">
            <Button
              secondary
              label={<FormattedMessage id="filter_clear_filter" />}
              className="mr-2"
              startIcon={EraseIcon}
              width="50%"
              onClick={this.handleClearFilters}
            />
            <Button
              secondary
              label={<FormattedMessage id="common_cancel" />}
              className="ml-2"
              width="45%"
              onClick={this.handleCancelFilters}
            />
          </Div>
        </IsMobile>
      </Div>
    );
  };

  render() {
    const { optionsData } = this.state;
    return (
      <CardWrapper
        ref={(filterRef) => {
          this.filterRef = filterRef;
        }}
        id="filter-pop-over-feature"
        height={[optionsData.length > 1 ? "100% !important" : "", "unset !important", "unset !important", "unset !important"]}
      >
        <IsDesktop>
          <CardHeader>
            <MDLabel alignSelf="center" fontSize="18px">
              <FormattedMessage id="header_filter" defaultMessage="Filter" />
            </MDLabel>
          </CardHeader>
        </IsDesktop>
        <IsMobile>
          <CardHeader justifyContent="space-between !important" px={3}>
            <MDLabel alignSelf="center" fontSize="18px">
              <FormattedMessage id="header_filter" defaultMessage="Filter" />
            </MDLabel>
            <MDImage
              src={icons.CrossCircle}
              alt="icon"
              onClick={this.handleCancelFilters}
              alignSelf="center"
              height="21px"
              width="18px"
            />
          </CardHeader>
        </IsMobile>
        <Div>{this.renderFilter()}</Div>
      </CardWrapper>
    );
  }
}
FilterPopover.propTypes = {
  /**
   * Options to be shown in filter
   */
  options: PropTypes.node,
  /**
   * Function to open and close the filter
   */
  closeFilter: PropTypes.func,
  /**
   * Apply filter
   */
  onApply: PropTypes.func,
};
FilterPopover.defaultProps = {
  options: null,
  closeFilter: undefined,
  onApply: undefined,
};
export default FilterPopover;
